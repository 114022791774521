import React from "react"
import { NeurondCard } from "../Cards"
import Row from "react-bootstrap/Row"
import "./SecondSection.scss"

const second_section_data = [
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/ArtificialIntelligencePage/AI_Page_Second_Section_1.jpg`,
    title: "Machine Learning",
    subTitle: "Discover hidden insight",
    description: `Machine learning and... on trusted insights rapidly.`,
    // slug: "machine-learning",
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/ArtificialIntelligencePage/AI_Page_Second_Section_2.jpg`,
    title: "Natural Language Processing",
    subTitle: "Understand the intent",
    description: `Natural language processing... solve your unique challenges.`,
    // slug: "natural-language-processing",
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/ArtificialIntelligencePage/AI_Page_Second_Section_3.jpg`,
    title: "Computer Vision",
    subTitle: "See through the eyes of AI",
    description: `We create tools... from video or images.`,
    // slug: "computer-vision",
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/ArtificialIntelligencePage/AI_Page_Second_Section_4.jpg`,
    title: "Forecasting & Optimization",
    subTitle: "Know the future, never miss a thing",
    description: `Neurond AI supports all... for your organization faster.`,
    // slug: "forecasting-and-optimization",
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/ArtificialIntelligencePage/AI_Page_Second_Section_5.jpg`,
    title: "AI Powered Labelling",
    subTitle: "Save time, minimize effort and increase accuracy",
    description: `Don’t waste your time... at a cost-effective rate.`,
    // slug: "ai-powered-labelling",
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/ArtificialIntelligencePage/AI_Page_Second_Section_6.jpg`,
    title: "Chatbots",
    subTitle: "Seamless interactions. Anytime, anywhere",
    description: `We develop sophisticated... with your customers seamlessly.`,
    // slug: "chatbots",
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/ArtificialIntelligencePage/AI_Page_Second_Section_7.jpg`,
    title: "Recommendation Systems",
    subTitle: "Propose the best solution",
    description: `When you understand... most importantly conversion.`,
    // slug: "recommendation-systems",
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/ArtificialIntelligencePage/AI_Page_Second_Section_8.jpg`,
    title: "Custom Model Building",
    subTitle: "Meet your unique business needs",
    description: `Our world class... off the shelf AI solution.`,
    // slug: `custom-model-building`,
  },
]

const SecondSection = () => (
  <section className="second-section margin-bot-2">
    <div className="wrapper">
      <Row className="wrapper__row">
        {second_section_data.map((item, index) => {
          return (
            <div
              className="wrapper__row__col-md-6 shadow-on card-title-left"
              key={index}
            >
              <NeurondCard
                title={item.title}
                description={item.description}
                imgLink={item.imgLink}
                subTitle={item.subTitle}
                isShowBottom={false}
              />
            </div>
          )
        })}
      </Row>
    </div>
  </section>
)

export default SecondSection
